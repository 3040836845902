import * as React from 'react';
export const Macavity1Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#EFD7F6" d="M0 0h48v48H0z" />
    <path
      fill="#AC9EF2"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M18.25 29c0 2.9557-.5461 5.5953-1.3972 7.4678-.882 1.9403-1.947 2.7822-2.8528 2.7822-.9058 0-1.9708-.8419-2.8528-2.7822C10.2961 34.5953 9.75 31.9557 9.75 29s.5461-5.5953 1.3972-7.4678c.882-1.9403 1.947-2.7822 2.8528-2.7822.9058 0 1.9708.8419 2.8528 2.7822C17.7039 23.4047 18.25 26.0443 18.25 29ZM38.25 29c0 2.9557-.5461 5.5953-1.3972 7.4678-.882 1.9403-1.947 2.7822-2.8528 2.7822-.9058 0-1.9708-.8419-2.8528-2.7822C30.2961 34.5953 29.75 31.9557 29.75 29s.5461-5.5953 1.3972-7.4678c.882-1.9403 1.947-2.7822 2.8528-2.7822.9058 0 1.9708.8419 2.8528 2.7822C37.7039 23.4047 38.25 26.0443 38.25 29Z"
    />
    <path
      fill="#DED8FA"
      d="M42.9744 48H5.0256C6.57 40.6194 14.4772 35 24 35c9.5226 0 17.4301 5.6194 18.9744 13Z"
    />
    <path
      fill="#1D1D1D"
      d="M44 48c-1.5663-8.0394-10.0519-14-20-14S5.5663 39.9606 4 48h1.5703C7.1313 41.0162 14.6654 35.5474 24 35.5474c9.3345 0 16.8687 5.4688 18.4297 12.4526H44Z"
    />
    <path
      fill="#E1AB87"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24 39c6.3513 0 11.5-5.1487 11.5-11.5S30.3513 16 24 16s-11.5 5.1487-11.5 11.5S17.6487 39 24 39Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M26.5466 34.0491c-1.5747 1.5164-3.791 1.2831-4.7824-.175"
    />
    <path
      fill="#F7907E"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24 6c-6.8432 0-12.4515 5.2876-12.9621 12-.0419.5507.4098 1 .9621 1h24c.5523 0 1.004-.4493.9621-1C36.4515 11.2876 30.8432 6 24 6Z"
    />
    <path
      fill="#F7907E"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M10.8673 16.25c-.8709 0-1.6092.6404-1.7324 1.5025l-.7143 5C8.27 23.8068 9.0881 24.75 10.153 24.75h27.694c1.065 0 1.883-.9432 1.7324-1.9975l-.7143-5c-.1231-.8621-.8615-1.5025-1.7324-1.5025H10.8673Z"
    />
    <path
      fill="#000"
      d="M18.5002 13.8662c.4783-.2762.6421-.8878.366-1.366-.2762-.4783-.8878-.6422-1.366-.3661-.4783.2762-.6422.8878-.3661 1.3661.2762.4783.8878.6421 1.3661.366ZM20.5002 11.8662c.4783-.2762.6421-.8878.366-1.366-.2762-.4783-.8878-.6422-1.366-.3661-.4783.2762-.6422.8878-.3661 1.3661.2762.4783.8878.6421 1.3661.366Z"
      opacity={0.2}
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M30.274 30.637c0-.9041-.7329-1.637-1.637-1.637S27 29.7329 27 30.637M21.274 30.637c0-.9041-.7329-1.637-1.637-1.637S18 29.7329 18 30.637"
    />
  </svg>
);
