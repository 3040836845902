import * as React from 'react';
export const Birdy1Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#EFD7F6" d="M0 0h48v48H0z" />
    <path
      fill="#FFEDCD"
      d="M44.2914 48H4.0561c.7767-10.2145 9.4869-18.2656 20.1177-18.2656 10.6307 0 19.3409 8.0511 20.1176 18.2656Z"
    />
    <path
      fill="#1D1D1D"
      d="M44.7393 48c-.7782-10.6205-9.8245-19-20.8697-19C12.8245 29 3.7782 37.3795 3 48h1.5042c.775-9.8086 9.1491-17.5313 19.3654-17.5313 10.2164 0 18.5905 7.7227 19.3655 17.5313h1.5042Z"
    />
    <circle
      cx={24}
      cy={23.864}
      r={13.25}
      fill="#FFEDCD"
      stroke="#1D1D1D"
      strokeWidth={1.5}
    />
    <circle
      cx={29.688}
      cy={21.798}
      r={3.5}
      fill="#fff"
      stroke="#1D1D1D"
      strokeWidth={1.5}
    />
    <circle
      cx={18.25}
      cy={21.798}
      r={3.5}
      fill="#fff"
      stroke="#1D1D1D"
      strokeWidth={1.5}
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M22.8472 10.7366c3.2294-.2517 7.8525-.8948 6.1684-4.7366"
    />
    <path
      fill="#FFCE74"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M22.0048 28.548c-.5542 0-1.0073-.4519-.9589-1.004.2489-2.8394 1.4768-4.996 2.9541-4.996s2.7052 2.1566 2.9541 4.996c.0484.5521-.4047 1.004-.9589 1.004h-3.9904Z"
    />
    <path stroke="#1D1D1D" strokeWidth={1.5} d="M22 22.4286h4" />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M26.5466 31.5971c-1.5747 1.5164-3.791 1.2831-4.7824-.175"
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="m10.9999 19.548 3.3752 1.7066M36.3751 19.548l-3.3752 1.7066"
    />
  </svg>
);
