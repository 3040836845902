import * as React from 'react';
export const AnimalAvatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#EFD7F6" d="M0 0h48v48H0z" />
    <path
      fill="#F7907E"
      d="M42 48H5c0-11.0457 8.2827-20 18.5-20S42 36.9543 42 48Z"
    />
    <path
      fill="#1D1D1D"
      d="M43 48c0-11.598-8.5066-21-19-21S5 36.402 5 48h1.5095c0-10.9031 7.965-19.4858 17.4905-19.4858 9.5255 0 17.4906 8.5827 17.4906 19.4858H43Z"
    />
    <path
      fill="#FBC0B5"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M36.25 23.5c0 6.4619-5.4565 11.75-12.25 11.75S11.75 29.9619 11.75 23.5 17.2065 11.75 24 11.75s12.25 5.2881 12.25 11.75Z"
    />
    <path
      fill="#1D1D1D"
      d="M27.8749 27.1976c-.0204 2.1227-1.7475 3.8371-3.875 3.8371-2.1274 0-3.8545-1.7144-3.8749-3.8371h7.7499Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M26.5845 22.7027c.2152.7492.9056 1.2973 1.724 1.2973s1.5088-.5481 1.724-1.2973M17.9675 22.7027c.2153.7492.9057 1.2973 1.7241 1.2973.8183 0 1.5087-.5481 1.724-1.2973"
    />
    <ellipse cx={17.08} cy={27.362} fill="#F7907E" rx={1.887} ry={1.754} />
    <ellipse cx={31.055} cy={27.362} fill="#F7907E" rx={1.887} ry={1.754} />
    <path
      fill="#C874E0"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M34.9524 19.9888c-3.8145-.6306-11.1175-7.1859-8.2299-7.9888 4.8257-1.3418 8.7742 4.1425 8.2299 7.9888Z"
    />
    <path
      fill="#C874E0"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M11.5344 19.9773c5.7156-.7539 17.6958-7.6512 13.8126-8.8092-6.4897-1.9352-13.2648 3.6017-13.8126 8.8092Z"
    />
    <path stroke="#1D1D1D" strokeWidth={1.5} d="M22.75 35v13M28 40.25h7" />
  </svg>
);
