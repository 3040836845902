import * as React from 'react';
export const Bloomberg2Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#FCD7D1" d="M0 0h48v48H0z" />
    <path
      fill="#99E2CD"
      d="M43.1725 48H4.8081c2.9042-8.8358 10.397-15.1272 19.1822-15.1272 8.7852 0 16.2778 6.2914 19.1822 15.1272Z"
    />
    <path
      fill="#1D1D1D"
      d="M43.9999 47.9999c-2.9396-9.3274-10.7843-16-19.9999-16-9.2157 0-17.0604 6.6726-20 16h1.6397C8.5059 39.6562 15.6457 33.7454 24 33.7454c8.3542 0 15.494 5.9108 18.3603 14.2545h1.6396Z"
    />
    <path
      fill="#AC9EF2"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M22.4164 41.3957c.6305 1.3401 2.5367 1.3401 3.1671 0l1.2952-2.7533a.2501.2501 0 0 1 .3644-.102l2.869 1.9012c1.2898.8548 2.973-.2622 2.6864-1.7829l-.4501-2.3877a.25.25 0 0 1 .2774-.2942l3.1514.4028c1.6451.2103 2.6327-1.7727 1.4736-2.9589l-1.5324-1.5683c-.13-.1331-.0744-.3564.1028-.4129l2.4207-.7723c1.6241-.5182 1.6241-2.8163 0-3.3344L34.9734 26.29a.2501.2501 0 0 1-.174-.2382v-2.1103c0-.9665-.7835-1.75-1.75-1.75h-.9303a.25.25 0 0 1-.241-.1838l-.7463-2.7179c-.3358-1.2232-1.8209-1.6939-2.8001-.8875l-1.1228.9248a.25.25 0 0 1-.3852-.0865l-1.2402-2.6364c-.6304-1.3401-2.5366-1.3401-3.1671 0l-1.1917 2.5332a.25.25 0 0 1-.4003.073l-.7066-.6856c-.9049-.8781-2.4133-.54-2.8568.6404l-1.0763 2.8642a.25.25 0 0 1-.234.1621h-1.0002c-.9665 0-1.75.7835-1.75 1.75v2.1103a.2501.2501 0 0 1-.174.2382L9.758 27.3328c-1.624.5181-1.624 2.8162 0 3.3344l2.4207.7723c.1772.0565.2329.2798.1029.4129l-1.5324 1.5683c-1.1591 1.1862-.1716 3.1692 1.4735 2.9589l3.1514-.4028a.25.25 0 0 1 .2774.2942l-.45 2.3877c-.2866 1.5207 1.3965 2.6377 2.6864 1.7829l2.869-1.9012a.25.25 0 0 1 .3643.102l1.2952 2.7533Z"
    />
    <path
      fill="#AC9EF2"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M25.6071 7.8342c-.6076-1.4099-2.6067-1.4099-3.2143 0l-1.2806 2.9718a.25.25 0 0 1-.3762.1036l-2.8072-2.0307c-1.2713-.9197-3.0171.1694-2.7502 1.7156l.4925 2.8525a.25.25 0 0 1-.2809.2902l-3.3045-.4611c-1.6147-.2253-2.6331 1.6855-1.5458 2.9003l1.8512 2.0682c.119.1329.0644.3441-.1041.4028l-2.8223.983c-1.5658.5453-1.5659 2.7598 0 3.3052l3.568 1.2427a.25.25 0 0 1 .1678.2361v2.4823c0 .9665.7835 1.75 1.75 1.75h1.0418a.25.25 0 0 1 .2286.1488l.7704 1.7408a1.7499 1.7499 0 0 0 1.9351 1.0094l2.0033-.3905a.2499.2499 0 0 1 .2243.0683l1.6109 1.6047c.6829.6803 1.7873.6803 2.4701 0l1.611-1.6047a.2497.2497 0 0 1 .2242-.0683l2.0033.3905a1.7499 1.7499 0 0 0 1.9351-1.0094l.7705-1.7408a.2498.2498 0 0 1 .2286-.1488h1.0417c.9665 0 1.75-.7835 1.75-1.75v-2.4823a.2501.2501 0 0 1 .1678-.2361l3.568-1.2427c1.5659-.5454 1.5659-2.7599 0-3.3052l-2.8223-.983c-.1685-.0587-.2231-.2699-.1041-.4028l1.8512-2.0682c1.0873-1.2148.0689-3.1256-1.5458-2.9003l-3.3045.4611a.25.25 0 0 1-.2809-.2902l.4925-2.8525c.267-1.5462-1.4789-2.6353-2.7502-1.7156l-2.8072 2.0307a.25.25 0 0 1-.3761-.1036l-1.2807-2.9718Z"
    />
    <path
      fill="#FCD7D1"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24 35.9999c6.0751 0 10.9999-4.9248 10.9999-10.9999 0-6.0752-4.9248-11-10.9999-11-6.0752 0-11 4.9248-11 11 0 6.0751 4.9248 10.9999 11 10.9999Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m19 26 2-1.5-2-1.5M29 23l-2 1.5 2 1.5"
    />
    <path
      fill="#1D1D1D"
      d="M21 29c.0158 1.6595 1.3529 2.9999 3 2.9999S26.9841 30.6595 27 29h-6Z"
    />
  </svg>
);
