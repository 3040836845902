import * as React from 'react';
export const Macavity4Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#FCD7D1" d="M0 0h48v48H0z" />
    <path
      fill="#DFB0ED"
      d="M43 48H6c1.5057-7.3806 9.2153-13 18.5-13 9.2846 0 16.9943 5.6194 18.5 13Z"
    />
    <path
      fill="#1D1D1D"
      d="M44 48c-1.5272-8.0394-9.8006-14-19.5-14S6.5272 39.9606 5 48h1.531c1.522-6.9838 8.8677-12.4526 17.969-12.4526 9.1011 0 16.447 5.4688 17.9689 12.4526H44Z"
    />
    <path
      fill="#DFB0ED"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M23.9993 40.0655c8.2906 0 15.0115-6.6509 15.0115-14.8551 0-8.2043-6.7209-14.8552-15.0115-14.8552S8.9878 17.0061 8.9878 25.2104c0 8.2042 6.7209 14.8551 15.0115 14.8551Z"
    />
    <path
      fill="#DFB0ED"
      d="M12.1151 18.3302s-2.8147-4.9257-1.4073-8.444c0 0 5.5511-1.3292 8.2094 3.8311"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12.1151 18.3302s-2.8147-4.9257-1.4073-8.444c0 0 5.5511-1.3292 8.2094 3.8311"
    />
    <path
      fill="#DFB0ED"
      d="M35.2577 18.3302s2.8146-4.9257 1.4073-8.444c0 0-5.5511-1.3292-8.2094 3.8311"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M35.2577 18.3302s2.8146-4.9257 1.4073-8.444c0 0-5.5511-1.3292-8.2094 3.8311"
    />
    <path
      fill="#FCD7D1"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M23.9989 37.4617c4.837 0 8.7581-4.2042 8.7581-9.3902 0-5.1861-3.9211-9.3902-8.7581-9.3902-4.837 0-8.7582 4.2041-8.7582 9.3902 0 5.186 3.9212 9.3902 8.7582 9.3902Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M26.5466 32.0491c-1.5747 1.5164-3.791 1.2831-4.7824-.175"
    />
    <path
      fill="#000"
      d="M26.8907 17.0805c.5613 0 1.0164-.455 1.0164-1.0164 0-.5613-.4551-1.0164-1.0164-1.0164-.5614 0-1.0164.4551-1.0164 1.0164 0 .5614.455 1.0164 1.0164 1.0164ZM20.3644 17.9702c.4861-.2806.6527-.9023.372-1.3884-.2807-.4861-.9023-.6527-1.3884-.372-.4861.2807-.6527.9023-.372 1.3884.2806.4861.9023.6527 1.3884.372ZM23.8795 14.5396c.4861-.2807.6527-.9023.372-1.3884-.2806-.4862-.9023-.6527-1.3884-.372-.4861.2806-.6527.9022-.372 1.3884.2807.4861.9023.6526 1.3884.372Z"
      opacity={0.2}
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m18.7676 25.776 2.8928 2.3934-2.8928 1.9069M29.543 25.776l-2.8929 2.3934 2.8929 1.9069"
    />
  </svg>
);
