import * as React from 'react';
export const Ludwig2Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#DED8FA" d="M0 0h48v48H0z" />
    <path
      fill="#D79CE9"
      fillRule="evenodd"
      d="M41.0001 48H0v-7.903C3.5021 33.3016 10.5987 28 19.8587 28c10.4034 0 20.2089 8.6262 21.1414 20Z"
      clipRule="evenodd"
    />
    <path
      fill="#EBCDF4"
      d="M7.5186 31.0273c.0798-.5465.5875-.9248 1.134-.845l26.37 3.8516c.5465.0799.9248.5876.845 1.1341l-.1446.9895-28.349-4.1407.1446-.9895ZM2.9639 35.4151c.0798-.5465.5875-.9248 1.134-.845l35.2374 5.1468c.5465.0798.9248.5876.845 1.1341l-.1446.9895-37.2164-5.4359.1446-.9895ZM.1606 40.0587c.0799-.5465.5876-.9248 1.134-.845l39.2973 5.7398c.5465.0798.9248.5875.8449 1.134l-.1445.9895L.0161 41.0482l.1445-.9895ZM0 46.0989v-2.0213L26.8448 48H12.9727L0 46.0989Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="m.0001 41.7708.0025-3.3386C3.8309 31.9306 10.903 27 19.976 27 30.8521 27 41.0322 36.1135 42 48h-1.5165c-.908-11.1512-10.4565-19.5151-20.5074-19.5151-8.9905 0-15.8861 5.1767-19.2976 11.8423l-.6784 1.4436Z"
      clipRule="evenodd"
    />
    <path
      fill="#E1AB87"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24.5613 34.3247c6.6274 0 12-5.3726 12-12s-5.3726-12-12-12-12 5.3726-12 12 5.3726 12 12 12Z"
    />
    <path
      fill="#1D1D1D"
      d="M18.5264 23.3509c.5812-.3386.7083-1.2037.2839-1.9324-.4245-.7287-1.2397-1.045-1.8209-.7064-.5812.3385-.7083 1.2036-.2839 1.9323.4244.7287 1.2397 1.045 1.8209.7065ZM28.343 17.6305c.5812-.3385.7083-1.2037.2839-1.9324-.4244-.7287-1.2396-1.0449-1.8209-.7064-.5812.3385-.7083 1.2037-.2838 1.9324.4244.7286 1.2396 1.0449 1.8208.7064Z"
    />
    <path
      fill="#1D1D1D"
      d="M31.1014 22.2678c1.1144 0 2.0177-.9034 2.0177-2.0177s-.9033-2.0177-2.0177-2.0177c-1.1143 0-2.0177.9034-2.0177 2.0177s.9034 2.0177 2.0177 2.0177ZM19.4496 29.0477c1.1143 0 2.0177-.9034 2.0177-2.0177 0-1.1144-.9034-2.0177-2.0177-2.0177-1.1144 0-2.0177.9033-2.0177 2.0177 0 1.1143.9033 2.0177 2.0177 2.0177Z"
      opacity={0.1}
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M26.5943 20.4496c-.7817 1.8905-2.7266 3.0175-4.7625 2.7811"
    />
    <path
      fill="#5A3DE6"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M12.8793 20.9461C18.1558 18.942 27.4957 9.3325 23.0191 9.0083c-7.4813-.5417-11.2645 5.5603-10.1398 11.9378Z"
    />
    <circle cx={15} cy={11} r={2} fill="#5A3DE6" />
    <path
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M15.6087 14c-.4638-.1299-1.5304-.5457-2.087-1.1693a2.0444 2.0444 0 0 1 0-2.7283c.6957-.7795 1.7392-1.559 2.4348-.7795.5565.6236.9276 2.0787 1.0435 2.7283"
    />
  </svg>
);
