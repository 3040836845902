import * as React from 'react';
export const Ludwig4Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#FFE3AF" d="M0 0h48v48H0z" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M41.0001 48H0v-7.903C3.5021 33.3016 10.5987 28 19.8587 28c10.4034 0 20.2089 8.6262 21.1414 20Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="m.0001 41.7708.0025-3.3386C3.8309 31.9306 10.903 27 19.976 27 30.8521 27 41.0322 36.1135 42 48h-1.5165c-.908-11.1512-10.4565-19.5151-20.5074-19.5151-8.9905 0-15.8861 5.1767-19.2976 11.8423l-.6784 1.4436Z"
      clipRule="evenodd"
    />
    <path
      fill="#C98B61"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24.5613 34.3247c6.6274 0 12-5.3726 12-12s-5.3726-12-12-12-12 5.3726-12 12 5.3726 12 12 12Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M29.7625 24c-.7816 1.8905-2.7266 3.0175-4.7625 2.7811"
    />
    <circle
      cx={19.423}
      cy={21.287}
      r={3.485}
      fill="#8C77EE"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      transform="rotate(-24.389 19.423 21.287)"
    />
    <circle
      cx={28.29}
      cy={17.266}
      r={3.485}
      fill="#8C77EE"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      transform="rotate(-24.389 28.29 17.266)"
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="m22.7478 19.9896 2.3572-1.0687M12.4008 22.5964l3.7787.1606M33.6277 12.9725l-2.3692 2.948"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M19.5 11.5c-3.0214.9738-6.9218 4.603-8.2331.6184"
    />
  </svg>
);
