import * as React from 'react';
export const Ludwig3Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#99E2CD" d="M0 0h48v48H0z" />
    <path
      fill="#D6CEF9"
      fillRule="evenodd"
      d="M41.0001 48H0v-7.903C3.5021 33.3016 10.5987 28 19.8587 28c10.4034 0 20.2089 8.6262 21.1414 20Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="m.0001 41.7708.0025-3.3386C3.8309 31.9306 10.903 27 19.976 27 30.8521 27 41.0322 36.1135 42 48h-1.5165c-.908-11.1512-10.4565-19.5151-20.5074-19.5151-8.9905 0-15.8861 5.1767-19.2976 11.8423l-.6784 1.4436Z"
      clipRule="evenodd"
    />
    <path
      fill="#E1AB87"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24.5613 34.3247c6.6274 0 12-5.3726 12-12s-5.3726-12-12-12-12 5.3726-12 12 5.3726 12 12 12Z"
    />
    <path
      fill="#1D1D1D"
      d="M18.5264 23.3508c.5812-.3385.7083-1.2036.2839-1.9323-.4245-.7287-1.2397-1.045-1.8209-.7065-.5812.3386-.7083 1.2037-.2839 1.9324.4244.7287 1.2397 1.045 1.8209.7064ZM28.343 17.6305c.5812-.3385.7083-1.2037.2839-1.9324-.4244-.7286-1.2396-1.0449-1.8209-.7064-.5812.3385-.7083 1.2037-.2838 1.9324.4244.7287 1.2396 1.0449 1.8208.7064Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M26.5943 20.4496c-.7817 1.8905-2.7266 3.0175-4.7625 2.7812"
    />
    <circle cx={8} cy={39} r={2} fill="#AC9EF2" />
    <circle cx={14} cy={45} r={2} fill="#AC9EF2" />
    <circle cx={21} cy={40} r={2} fill="#AC9EF2" />
    <circle cx={28} cy={45} r={2} fill="#AC9EF2" />
    <circle cx={32} cy={38} r={2} fill="#AC9EF2" />
    <circle cx={36} cy={45} r={2} fill="#AC9EF2" />
    <circle cx={14} cy={35} r={2} fill="#AC9EF2" />
    <circle cx={3} cy={45} r={2} fill="#AC9EF2" />
    <path
      fill="#AC9EF2"
      d="M21 47c-.7403 0-1.3866.4022-1.7324 1h3.4648c-.3458-.5978-.9921-1-1.7324-1Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M13.5 17c-.9561 3.027-1.4923 7.7388-5.5 6.5M16.5 13.5c-2.5396 1.9045-4.8061 6.5562-7.3433 3.2158"
    />
  </svg>
);
