import * as React from 'react';
export const Birdy4Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#FCD7D1" d="M0 0h48v48H0z" />
    <path
      fill="#EFD7F6"
      d="M44.2914 48H4.0561c.7767-10.4322 9.4869-18.6548 20.1177-18.6548 10.6307 0 19.3409 8.2226 20.1176 18.6548Z"
    />
    <path
      fill="#1D1D1D"
      d="M44.7393 48c-.7782-10.8468-9.8245-19.4048-20.8697-19.4048C12.8245 28.5952 3.7782 37.1532 3 48h1.5042c.775-10.0176 9.1491-17.9048 19.3654-17.9048 10.2164 0 18.5905 7.8872 19.3655 17.9048h1.5042Z"
    />
    <path
      fill="#EFD7F6"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M37.4141 23.9116c0 7.0753-5.9007 12.8458-13.2229 12.8458-7.3223 0-13.2229-5.7705-13.2229-12.8458s5.9006-12.8458 13.2229-12.8458c7.3222 0 13.2229 5.7705 13.2229 12.8458Z"
    />
    <ellipse
      cx={23.902}
      cy={28.325}
      fill="#FFB938"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      rx={3.486}
      ry={3.553}
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M27.3109 27.6455S25.9233 28.94 23.9275 28.99c-2.4456.0611-3.3751-1.3208-3.3751-1.3208"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M31.7947 23.8211c.1104-.7827-.3212-1.5403-1.0723-1.8086-.8915-.3261-1.879.095-2.2012.9975l-.1857.4617M16.2032 23.5039c-.1361-.77.2908-1.5579 1.0313-1.8524.8677-.3864 1.85.047 2.2317.9254l.1801.464"
    />
    <circle
      cx={22.352}
      cy={43.286}
      r={1.173}
      fill="#DFB0ED"
      transform="rotate(-78.926 22.352 43.286)"
    />
    <circle
      cx={20.659}
      cy={39.713}
      r={1.173}
      fill="#DFB0ED"
      transform="rotate(-78.926 20.66 39.713)"
    />
    <circle
      cx={24.191}
      cy={39.992}
      r={1.173}
      fill="#DFB0ED"
      transform="rotate(-78.926 24.191 39.992)"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M22.8472 11.1886c3.2294-.2517 7.8525-.8947 6.1684-4.7366"
    />
  </svg>
);
