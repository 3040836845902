import * as React from 'react';
export const Animal4Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#EFD7F6" d="M0 0h48v48H0z" />
    <path
      fill="#DFB0ED"
      d="M41.9999 47.9999h-37c0-11.598 8.2828-21 18.5-21 10.2173 0 18.5 9.402 18.5 21Z"
    />
    <path
      fill="#1D1D1D"
      d="M42.9999 47.9999c0-11.598-8.5066-21-19-21-10.4933 0-19 9.402-19 21h1.5095c0-10.9031 7.9651-19.4857 17.4905-19.4857 9.5255 0 17.4905 8.5826 17.4905 19.4857h1.5095Z"
    />
    <path
      fill="#FFCE74"
      d="M17.9824 14.2553c.0654-.849.4547-1.8391-.1117-2.618-1.2282-1.7357-5.2417-3.2359-6.6099-2.1102-1.3682 1.1256-.921 4.9667.1814 6.824.5267.9154 2.0207.9944 2.9949 1.109"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M17.9824 14.2553c.0654-.849.4547-1.8391-.1117-2.618-1.2282-1.7357-5.2417-3.2359-6.6099-2.1102-1.3682 1.1256-.921 4.9667.1814 6.824.5267.9154 2.0207.9944 2.9949 1.109"
    />
    <path
      fill="#FFCE74"
      d="M29.3749 14.7456c-.1314-.8413-.5968-1.798-.093-2.6188 1.0889-1.8263 4.973-3.6352 6.425-2.6198 1.4519 1.0154 1.3059 4.8796.3519 6.8173-.4536.9538-1.9369 1.1492-2.8992 1.3395"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M29.3749 14.7456c-.1314-.8413-.5968-1.798-.093-2.6188 1.0889-1.8263 4.973-3.6352 6.425-2.6198 1.4519 1.0154 1.3059 4.8796.3519 6.8173-.4536.9538-1.9369 1.1492-2.8992 1.3395"
    />
    <path
      fill="#FFCE74"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M36.2499 23.4999c0 6.4619-5.4565 11.75-12.25 11.75-6.7934 0-12.2499-5.2881-12.2499-11.75 0-6.4618 5.4565-11.7499 12.2499-11.7499 6.7935 0 12.25 5.2881 12.25 11.7499Z"
    />
    <path stroke="#1D1D1D" strokeWidth={1.5} d="M22.75 35v13" />
    <circle cx={26.058} cy={38.497} r={1.251} fill="#1D1D1D" />
    <circle cx={26.058} cy={43.895} r={1.251} fill="#1D1D1D" />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M26.0575 27.5372c-1.5747 1.5163-3.7909 1.2831-4.7824-.175"
    />
    <circle
      cx={19.431}
      cy={21.689}
      r={3.485}
      fill="#FFE3AF"
      stroke="#1D1D1D"
      strokeWidth={1.5}
    />
    <circle
      cx={29.168}
      cy={21.689}
      r={3.485}
      fill="#FFE3AF"
      stroke="#1D1D1D"
      strokeWidth={1.5}
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M22.9955 21.8805h2.5882M12.4953 19.9822l3.3751 1.7066M35.802 19.9821l-3.3751 1.7067"
    />
  </svg>
);
