import * as React from 'react';
export const Animal3Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#FFEDCD" d="M0 0h48v48H0z" />
    <path
      fill="#E5F8F3"
      d="M42.7621 48H5c0-11.598 8.4533-21 18.881-21 10.4278 0 18.8811 9.402 18.8811 21Z"
    />
    <path
      fill="#1D1D1D"
      d="M43 48c0-11.598-8.5066-21-19-21S5 36.402 5 48h1.5095c0-10.9031 7.9651-19.4858 17.4905-19.4858 9.5255 0 17.4906 8.5827 17.4906 19.4858H43Z"
    />
    <path
      fill="#FFCE74"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M36.25 23.5c0 6.4619-5.4565 11.75-12.25 11.75S11.75 29.9619 11.75 23.5 17.2065 11.75 24 11.75s12.25 5.2881 12.25 11.75Z"
    />
    <path
      fill="#1D1D1D"
      d="M27.875 27.1976c-.0204 2.1227-1.7475 3.8371-3.8749 3.8371-2.1275 0-3.8546-1.7144-3.875-3.8371h7.7499Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M26.5845 23.9999c.2152-.7491.9056-1.2973 1.724-1.2973s1.5088.5482 1.724 1.2973M17.9675 23.9999c.2153-.7491.9057-1.2973 1.7241-1.2973.8183 0 1.5087.5482 1.724 1.2973"
    />
    <ellipse cx={17.08} cy={27.362} fill="#FFB938" rx={1.887} ry={1.754} />
    <ellipse cx={31.055} cy={27.362} fill="#FFB938" rx={1.887} ry={1.754} />
    <path stroke="#1D1D1D" strokeWidth={1.5} d="M22.75 35v13" />
    <circle cx={26.058} cy={38.497} r={1.251} fill="#1D1D1D" />
    <circle cx={26.058} cy={43.895} r={1.251} fill="#1D1D1D" />
    <path
      fill="#8C77EE"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M11.5233 26.1638c2.4511-2.4988 4.8293-11.3423 2.4197-10.4226-4.0269 1.5369-4.473 7.791-2.4197 10.4226ZM36.0844 26.1638c-2.4512-2.4988-4.8294-11.3423-2.4197-10.4226 4.0269 1.5369 4.473 7.791 2.4197 10.4226Z"
    />
    <path
      fill="#8C77EE"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M10.9172 19.536c4.4019-.5927 12.6249-7.9919 9.2436-9.0429-5.6507-1.7565-10.0223 4.5166-9.2436 9.0429Z"
    />
    <path
      fill="#8C77EE"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M37.2964 19.116c-6.836-.0887-20.8338-6.7029-16.2965-9.616 6.0792-3.9031 16.4825 2.6265 16.2965 9.616Z"
    />
  </svg>
);
