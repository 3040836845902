import * as React from 'react';
export const Barry4Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#BFEDE0" d="M0 0h48v48H0z" />
    <path
      fill="#FDDFDA"
      d="M43 48H6c1.5057-7.3806 9.2153-13 18.5-13 9.2846 0 16.9943 5.6194 18.5 13Z"
    />
    <path
      fill="#1D1D1D"
      d="M44 48c-1.5272-8.0394-9.8006-14-19.5-14S6.5272 39.9606 5 48h1.531c1.522-6.9838 8.8677-12.4526 17.969-12.4526 9.1011 0 16.447 5.4688 17.9689 12.4526H44Z"
    />
    <path
      fill="#F7907E"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m10.7393 27.2456-.0407-.2229c-.982-5.3864 1.6584-10.7859 6.6157-13.5293 4.0772-2.2563 9.1157-2.3365 13.2846-.2382 5.5899 2.8136 8.5154 8.9048 7.0455 14.7687l-.0511.2039c-.6976 2.783-2.746 5.4567-4.0434 8.0366l-1.0717 1.5617c-4.5595 7.6734-13.1737 7.5727-17.087-.5217-1.5289-3.1626-4.0264-6.6277-4.6519-10.0588Z"
    />
    <path
      fill="#FCD7D1"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24 38.5696c4.6557 0 8.4299-3.828 8.4299-8.5502 0-4.7222-3.7742-8.5503-8.4299-8.5503-4.6557 0-8.4299 3.8281-8.4299 8.5503S19.3443 38.5696 24 38.5696Z"
    />
    <path
      fill="#4DCCA8"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m15.8916 10.6017 2.2709 3.1155a7.481 7.481 0 0 0 11.8739.2832l2.7347-3.3987c-1.2712-.7901-4.3589-1.335-6.024.6648l-2.4626-5.4844-2.8375 5.4844c-1.98-1.8182-4.8407-1.5458-5.5554-.6648Z"
    />
    <path
      fill="#FDDFDA"
      d="M17.6108 17.7047a.913.913 0 0 0 .913-.913.913.913 0 1 0-1.826 0 .913.913 0 0 0 .913.913ZM31.6626 18.9175a.913.913 0 1 0 0-1.826.913.913 0 0 0 0 1.826ZM27.1081 19.5308a.913.913 0 1 0 0-1.8261.913.913 0 0 0 0 1.8261ZM21.7246 20.4439a.913.913 0 1 0-.0001-1.8261.913.913 0 0 0 .0001 1.8261ZM31.7712 22.7585a.913.913 0 0 0 .913-.9131.913.913 0 0 0-.913-.913.913.913 0 0 0-.913.913.913.913 0 0 0 .913.9131ZM34.7587 26.6379a.913.913 0 1 0 0-1.8262.913.913 0 0 0 0 1.8262ZM16.6977 22.2699a.913.913 0 1 0 0-1.8261.913.913 0 0 0 0 1.8261ZM13.665 25.8261a.913.913 0 0 0 .913-.9131.913.913 0 0 0-.913-.913.913.913 0 0 0-.913.913.913.913 0 0 0 .913.9131Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M28.7857 29.5194c0-.9041-.7329-1.637-1.637-1.637s-1.637.7329-1.637 1.637M22.4881 29.5877c0-.9041-.7329-1.637-1.637-1.637s-1.637.7329-1.637 1.637"
    />
    <path
      fill="#1D1D1D"
      d="M21.0193 32.2269c.0157 1.6328 1.3442 2.9516 2.9807 2.9516 1.6366 0 2.9651-1.3188 2.9808-2.9516h-5.9615Z"
    />
  </svg>
);
