import * as React from 'react';
export const Ludwig1Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#FCD7D1" d="M0 0h48v48H0z" />
    <path
      fill="#BDB1F5"
      fillRule="evenodd"
      d="M41.0001 48H0v-7.903C3.5021 33.3016 10.5987 28 19.8587 28c10.4034 0 20.2089 8.6262 21.1414 20Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="m.0001 41.7708.0025-3.3386C3.8309 31.9306 10.903 27 19.976 27 30.8521 27 41.0322 36.1135 42 48h-1.5165c-.908-11.1512-10.4565-19.5151-20.5074-19.5151-8.9905 0-15.8861 5.1767-19.2976 11.8423l-.6784 1.4436Z"
      clipRule="evenodd"
    />
    <path
      fill="#E1AB87"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24.5613 34.3247c6.6274 0 12-5.3726 12-12s-5.3726-12-12-12-12 5.3726-12 12 5.3726 12 12 12Z"
    />
    <path
      fill="#1D1D1D"
      d="M18.5264 23.3509c.5812-.3386.7083-1.2037.2839-1.9324-.4245-.7287-1.2397-1.045-1.8209-.7064-.5812.3385-.7083 1.2036-.2839 1.9323.4244.7287 1.2397 1.045 1.8209.7065ZM28.343 17.6305c.5812-.3385.7083-1.2037.2839-1.9324-.4244-.7287-1.2396-1.0449-1.8209-.7064-.5812.3385-.7083 1.2037-.2838 1.9324.4244.7286 1.2396 1.0449 1.8208.7064Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M26.7625 21c-.7816 1.8905-2.7266 3.0175-4.7625 2.7811"
    />
    <path
      fill="#F7907E"
      d="M31.1014 22.2678c1.1144 0 2.0177-.9033 2.0177-2.0177 0-1.1143-.9033-2.0177-2.0177-2.0177-1.1143 0-2.0177.9034-2.0177 2.0177 0 1.1144.9034 2.0177 2.0177 2.0177ZM19.4496 29.0476c1.1143 0 2.0177-.9034 2.0177-2.0177s-.9034-2.0177-2.0177-2.0177c-1.1144 0-2.0177.9034-2.0177 2.0177s.9033 2.0177 2.0177 2.0177Z"
      opacity={0.7}
    />
    <path
      fill="#8B651E"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M15.9687 18.2193c3.6078-1.3704 9.9938-7.9407 6.933-8.1624-5.1153-.3704-7.702 3.8018-6.933 8.1624Z"
    />
  </svg>
);
