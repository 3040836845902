import * as React from 'react';
export const Barry3Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#FCD7D1" d="M0 0h48v48H0z" />
    <path
      fill="#FFDC9B"
      d="M43 48H6c1.5057-7.3806 9.2153-13 18.5-13 9.2846 0 16.9943 5.6194 18.5 13Z"
    />
    <path
      fill="#1D1D1D"
      d="M44 48c-1.5272-8.0394-9.8006-14-19.5-14S6.5272 39.9606 5 48h1.531c1.522-6.9838 8.8677-12.4526 17.969-12.4526 9.1011 0 16.447 5.4688 17.9689 12.4526H44Z"
    />
    <path
      fill="#E1AB87"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M35.25 26c0 6.2132-5.0368 11.25-11.25 11.25S12.75 32.2132 12.75 26c0-3.12 1.2692-6.3257 3.3354-8.7486C18.1505 14.8297 20.9559 13.25 24 13.25c3.0441 0 5.8495 1.5797 7.9146 4.0014C33.9808 19.6743 35.25 22.88 35.25 26Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M28.5716 28.637c0-.9041-.7329-1.637-1.637-1.637s-1.637.7329-1.637 1.637M22.274 28.7054c0-.9041-.7329-1.637-1.637-1.637S19 27.8013 19 28.7054"
    />
    <path
      fill="#1D1D1D"
      d="M20.8052 31.3446c.0157 1.6328 1.3442 2.9516 2.9807 2.9516s2.965-1.3188 2.9808-2.9516h-5.9615Z"
    />
    <path
      fill="#F7907E"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M36 14c0 6.7336 2.8441 9.3735 3.9992 10.2271 1.3272.9807 3.9606 3.1514 2.6404 7.0907-1.3202 3.1515-4.0873 5.1334-7.2608 2.3636C32.2053 30.9116 31.9998 19.1304 32 14h4ZM12 14c0 6.7336-2.8441 9.3735-3.9992 10.2271-1.3272.9807-3.9606 3.1514-2.6404 7.0907 1.3202 3.1515 4.0873 5.1334 7.2608 2.3636C15.7947 30.9116 16.0002 19.1304 16 14h-4Z"
    />
    <path
      fill="#F7907E"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M36.25 14.6857c0 2.1071-1.2951 4.4934-3.5404 6.3821C30.4805 22.9429 27.3985 24.25 24 24.25c-3.3985 0-6.4805-1.3071-8.7096-3.1822-2.2453-1.8887-3.5404-4.275-3.5404-6.3821 0-2.0705 1.256-4.036 3.4743-5.5181C17.4374 7.6889 20.5379 6.75 24 6.75c3.4621 0 6.5626.939 8.7757 2.4176 2.2183 1.4821 3.4743 3.4476 3.4743 5.5181Z"
    />
    <path
      fill="#FCD7D1"
      d="M20 13c.5523 0 1-.4477 1-1s-.4477-1-1-1-1 .4477-1 1 .4477 1 1 1ZM20 18c.5523 0 1-.4477 1-1s-.4477-1-1-1-1 .4477-1 1 .4477 1 1 1ZM17 15c.5523 0 1-.4477 1-1s-.4477-1-1-1-1 .4477-1 1 .4477 1 1 1Z"
    />
  </svg>
);
