import * as React from 'react';
export const Bloomberg1Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#F7EBFB" d="M0 0h48v48H0z" />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M23.3994 43.915c-.4804-.1398-.9008-.4796-1.181-.8992l-1.2211-1.8585c0-.02-.02-.02-.04 0l-1.8617 1.259c-.2002.1398-.4003.2398-.6205.2997-1.2412.3397-2.4022-.4396-2.6224-1.6187l-.4204-2.2582c0-.02-.02-.02-.04-.02l-2.262.4797c-.1401.0199-.2803.0399-.4204.0399-.7807 0-1.5013-.4196-1.8617-1.159-.2201-.4397-.2602-.9393-.1601-1.4189l.4604-2.1783c0-.0199 0-.0199-.02-.0399l-2.202-.3997c-.5004-.0999-.9608-.3397-1.2811-.7394-.6006-.7394-.6006-1.7386-.1001-2.478l1.3012-1.8985v-.04l-1.8617-1.219c-.4204-.2797-.7607-.6794-.9008-1.179-.2602-.9193.1-1.8385.8608-2.3381l1.9217-1.259c.02 0 .02-.02 0-.04l-1.2612-1.8385c-.2802-.4197-.4403-.9193-.3803-1.4389.1001-.9392.8007-1.6586 1.6815-1.8385l2.222-.3997c.02 0 .02-.02.02-.0399l-.4604-2.1783c-.1001-.4796-.06-.9792.1601-1.4188.3804-.7195 1.081-1.1591 1.8617-1.1591.1401 0 .2803.02.4204.04l2.242.4596c.02 0 .02 0 .04-.02l.4004-2.1383c.1801-.9392.9408-1.7186 1.9017-1.7985.4804-.04.9408.0799 1.3412.3597l1.9017 1.2989h.04l1.2211-1.8585c.2802-.4196.6806-.7594 1.181-.8993.9209-.2597 1.8417.1 2.3421.8594l1.2612 1.9184c0 .02.02.02.04 0l1.8617-1.259c.2001-.1399.4003-.2398.6205-.2997 1.2411-.3397 2.4022.4396 2.6224 1.6187l.4203 2.2582c0 .0199.0201.0199.0401.0199l2.242-.4596c.1401-.02.2802-.04.4203-.04.7807 0 1.5014.4197 1.8617 1.1591.2202.4397.2602.9393.1601 1.4189l-.4604 2.1782c0 .02 0 .02.0201.04l2.2019.4196c.5005.1.9609.3398 1.2812.7395.6005.7394.6205 1.7386.1001 2.478l-1.3012 1.8984v.04l1.8817 1.219c.4203.2798.7606.6795.9008 1.1791.2602.9192-.1001 1.8385-.8608 2.3381l-1.9217 1.259c-.02 0-.02.02 0 .04l1.2611 1.8385c.2803.4196.4404.9192.3804 1.4388-.1001.9393-.8008 1.6587-1.6815 1.8385l-2.2621.4197c-.02 0-.02.02-.02.04l.4604 2.1782c.1001.4796.0601.9792-.1601 1.4189-.3804.7194-1.081 1.159-1.8617 1.159-.1401 0-.2802-.0199-.4204-.0399l-2.242-.4597c-.02 0-.02 0-.04.02l-.4003 2.1383c-.1802.9393-.9409 1.7186-1.9017 1.7986-.4805.0399-.9409-.08-1.3412-.3597l-1.9017-1.299h-.0401l-1.2611 1.9185c-.4604.6594-1.3812 1.0191-2.3021.7593Z"
    />
    <path
      fill="#F7907E"
      d="M43.1725 48H4.8081c2.9043-7.7313 10.397-13.2364 19.1822-13.2364 8.7852 0 16.2779 5.5051 19.1822 13.2364Z"
    />
    <path
      fill="#1D1D1D"
      d="M44 48c-2.9396-8.1615-10.7844-14-20-14-9.2157 0-17.0604 5.8385-20 14h1.6397C8.5059 40.6992 15.6457 35.5273 24 35.5273c8.3543 0 15.494 5.1719 18.3603 12.4727H44Z"
    />
    <path
      fill="#FED491"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24 37c6.0751 0 11-4.9249 11-11s-4.9249-11-11-11-11 4.9249-11 11 4.9249 11 11 11Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M26.0108 32c-1.1903.9057-2.8205.9057-4.0108 0"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M29.274 27.637c0-.9041-.7329-1.637-1.637-1.637S26 26.7329 26 27.637M21.274 27.637c0-.9041-.7329-1.637-1.637-1.637S18 26.7329 18 27.637"
    />
    <path
      fill="#fff"
      d="M22.2307 18.461c.2958.443.7396.8016 1.2467.9493.972.2742 1.9439-.1055 2.4299-.8016l1.3312-2.025h.0423l2.0073 1.3711c.4226.2953.9086.4219 1.4157.3797 1.0143-.0844 1.8172-.9071 2.0074-1.8985V15l-8.411-2.5-9.1383 2.5.3495 1.436c.2324 1.2446 1.4579 2.0672 2.768 1.7086.2324-.0633.4437-.1687.655-.3164l1.9651-1.3289c.0212-.0211.0423-.0211.0423 0l1.2889 1.9617Z"
    />
    <path
      fill="#1D1D1D"
      d="M15.8951 16.5904c.3234-.0554.6486.0766.8855.3037.3453.331.8896.7309 1.4073.6007.13-.0325.2598-.0888.4082-.184l.009-.0058 1.8689-1.1612A.7932.7932 0 0 1 20.9323 16c.1715 0 .3887.0578.5529.2299a.6776.6776 0 0 1 .0987.1322l1.2047 1.6847.0014.002c.2052.2814.499.4882.7983.5691.646.1663 1.2572-.0726 1.5451-.445l1.2471-1.7432a.8155.8155 0 0 1 1.0967-.2162l1.9269 1.2093c.2663.1711.5664.2436.8905.2189.2966-.0227.6084-.2934.8505-.5657.2804-.3154.7182-.4772 1.1162-.3367.423.1493.6871.6035.4521.9857-.4384.7131-1.2692 1.1619-2.2989 1.2406-.6522.0499-1.2878-.1011-1.8354-.4517l-.0032-.0021-1.349-.8466-.8971 1.254-.0058.0076c-.6507.8563-1.9084 1.286-3.1312.969l-.0059-.0016c-.6766-.181-1.2363-.6082-1.6007-1.1096l-.003-.0041-.8526-1.1924-1.3112.8147c-.2576.1649-.5346.2946-.8507.3736-1.3335.335-2.6122-.2137-3.2314-1.1946-.2653-.4203.068-.9075.5578-.9914Z"
    />
  </svg>
);
